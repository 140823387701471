import React, { useEffect, useContext, useState, useMemo } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppContext from "../../../AppContext";
import styles from "./BlocksMainView.module.css";
import BlockDisplay from "../BlockDisplay/BlockDisplay";
import DialogSfpcRec from "../DialogSfpcRec/DialogSfpcRec";

import { INIT_NUM_BLOCKS } from "../../../constants";
import i18n from "../../../i18n";

export default function BlocksMainView() {
  // Hook to make the app rsponsive:
  const phoneScreen = useMediaQuery("(max-width: 768px)");
  const {
    settingSet,
    onOptimizeBlocks,
    incompApiData,
    currentBlockSetId,
    blockSetConfig,
    blocksSet,
    updateBlockSet,
    currentSetting,
    setErrorMessage,
    setIsErrorDialogOpen,
    applyBigsFirst,
    setApplyBigsFirst,
    removeExactOptResults,
  } = useContext(AppContext);

  const [isSfpcRecModalOpen, setIsSfpcRecModalOpen] = useState(false);

  const { blockCount, blockNames } = useMemo(() => {
    const blockConfig = blockSetConfig.find((e) => e.block_set_id === currentBlockSetId);
    if (blockConfig === undefined) {
      const count: number = INIT_NUM_BLOCKS;
      const names: string[] = [];
      const baseName: string = i18n.t("general.BlockDisplay.lumen");
      for (let i = 0; i < INIT_NUM_BLOCKS; i += 1) {
        names.push(`${baseName} ${i + 1}`);
      }
      return { blockCount: count, blockNames: names };
    }
    return { blockCount: blockConfig.block_count, blockNames: blockConfig.block_names };
  }, [currentBlockSetId, blockSetConfig]);

  useEffect(() => {
    if (applyBigsFirst) {
      onOptimizeBlocks(
        "bigs_first_algorithm",
        currentBlockSetId,
        settingSet.isSettingActive(currentBlockSetId),
      );
      removeExactOptResults();
      setApplyBigsFirst(false); // Reset the flag after optimization is performed
    }
  }, [applyBigsFirst]);

  // Function to move drugs
  const onMoveDrug = (drug: DrugOrSynonym, direction: "left" | "right") => {
    if (!blocksSet) return;
    try {
      if (direction === "left") {
        blocksSet.moveDrugLeft(drug, currentSetting);
      } else if (direction === "right") {
        blocksSet.moveDrugRight(drug, currentSetting);
      }
    } catch (e) {
      setErrorMessage((e as Error).message);
      setIsErrorDialogOpen(true);
    }
    updateBlockSet();
  };

  // Change numBlocks
  useEffect(() => {
    if (!blocksSet) return;
    blocksSet.setBlockSetId(currentBlockSetId, blockSetConfig);
    updateBlockSet();
    setApplyBigsFirst(true);
  }, [currentBlockSetId, blockSetConfig]);

  // Update when api-dependent props change
  useEffect(() => {
    if (!blocksSet) return;
    blocksSet.incompApiData = incompApiData;
    updateBlockSet();
  }, [incompApiData]);

  const gridStyle: React.CSSProperties = {};

  // A function to render blocks on a computer screen
  function bigScreenSettings() {
    if (blockCount <= 3) {
      gridStyle.gridTemplateColumns = `repeat(${blockCount}, 1fr)`;
      gridStyle.gridTemplateRows = "100%";
    } else if (blockCount === 4) {
      gridStyle.gridTemplateColumns = "repeat(2, 1fr)";
      gridStyle.gridTemplateRows = "repeat(2, 50%)";
    } else {
      gridStyle.gridTemplateColumns = "repeat(3, 1fr)";
      gridStyle.gridTemplateRows = "repeat(2, 50%)";
    }

    if (window.innerWidth <= 1024 && blockCount <= 3) {
      gridStyle.gridTemplateColumns = `repeat(${blockCount}, 1fr)`;
      gridStyle.gridTemplateRows = "100%";
    } else if (window.innerWidth <= 1024 && blockCount === 4) {
      gridStyle.gridTemplateColumns = "repeat(2, 1fr)";
      gridStyle.gridTemplateRows = "repeat(2, 50%)";
    } else if (window.innerWidth <= 1024) {
      gridStyle.gridTemplateColumns = "repeat(2, 1fr)";
      gridStyle.gridTemplateRows = "repeat(3, 50%)";
    }
  }

  // A function to render blocks on a phone screen
  function smallScreenSettings() {
    gridStyle.display = "flex";
  }

  // Actual choice of display depending on screen size:
  switch (phoneScreen) {
    case true:
      smallScreenSettings();
      break;
    default:
      bigScreenSettings();
  }

  return (
    <div className={styles.main} style={gridStyle} id="container-rampes">
      {blocksSet
        ? blocksSet.blocks.map((block, idx) => (
            <BlockDisplay
              block={block}
              title={blockNames[idx]}
              onIpcClick={() => setIsSfpcRecModalOpen(true)}
              numBlock={idx + 1}
              // eslint-disable-next-line react/no-array-index-key
              key={`block-${idx}`}
              onMoveDrug={onMoveDrug}
            />
          ))
        : null}
      <DialogSfpcRec
        isOpen={isSfpcRecModalOpen}
        handleClose={() => setIsSfpcRecModalOpen(false)}
        setIsOpen={setIsSfpcRecModalOpen}
      />
    </div>
  );
}
