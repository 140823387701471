import Settings from "./Settings";

export default class SettingSet {
  settings: Settings[];
  initNumBlocks: number;
  isActive: boolean;

  constructor(settings: Settings[], initNumBlocks: number) {
    this.settings = settings;
    this.initNumBlocks = initNumBlocks;
    const storedIsActive = localStorage.getItem("mainSwitchIsActive");
    this.isActive = storedIsActive !== null ? JSON.parse(storedIsActive) : true;
    this.loadActiveState(); // Load the isActive state for each setting from localStorage
  }

  /**
   * Sets all the hasChanged props of the settings to false
   */
  resetChanged() {
    this.settings.forEach((setting) => {
      setting.resetHasChanged();
    });
  }

  /**
   * Returns true if any of the hasChanged field in the settingSet
   * or children settings is set to true.
   */
  changedOccured() {
    for (let i = 0; i < this.settings.length; i += 1) {
      if (this.settings[i].hasChanged) {
        return true;
      }
    }
    return false;
  }

  /**
   * Saves the isActive state to localStorage
   */
  saveActiveState() {
    localStorage.setItem("mainSwitchIsActive", JSON.stringify(this.isActive));
    // Save the isActive state of each setting to localStorage
    this.settings.forEach((setting, index) => {
      localStorage.setItem(`isActive${index}`, JSON.stringify(setting.isActive));
    });
  }

  /**
   * Loads the isActive state for each setting from localStorage
   */
  loadActiveState() {
    this.settings.forEach((setting, index) => {
      const storedIsActive = localStorage.getItem(`isActive${index}`);
      if (storedIsActive !== null) {
        setting.isActive = JSON.parse(storedIsActive);
      }
    });
  }

  /**
   * Sets the isActive state and saves it to localStorage
   * @param cond boolean
   */
  setActive(cond: boolean) {
    this.isActive = cond;
    this.saveActiveState(); // Save the isActive state to localStorage
    // Apply the action to every setting:
    this.settings.forEach((setting) => {
      setting.setActive(cond);
    });
  }

  get ids() {
    const list: number[] = [];
    this.settings.forEach((setting) => {
      if (setting.id) {
        list.push(setting.id);
      }
    });
    return list;
  }

  get rampNumbers() {
    return this.settings.map((setting) => {
      return setting.blockSetConfigId;
    });
  }

  deleteByRamps(ramps: number) {
    this.settings = this.settings.filter((setting) => setting.blockSetConfigId !== ramps);
  }

  addSetting(configId: number, configs: BlockSetConfig[], rules: BlockRule[]) {
    this.settings.push(new Settings(configId, configs, rules));
  }

  selectByRamps(ramps: number | null) {
    const settingList = this.settings.filter(
      (setting) => setting.blockSetConfigId === ramps,
    );
    if (settingList.length > 0) {
      return settingList[0];
    }
    return null;
  }

  toJSON() {
    const list: object[] = [];
    this.settings.forEach((setting) => {
      list.push(setting.toJSON());
    });
    return list;
  }

  updateId(json: object) {
    this.settings.forEach((setting) => {
      setting.updateId(
        (json as any).settings.filter(
          (jsonSetting: any) => jsonSetting.block_set_id === setting.blockSetConfigId,
        )[0],
      );
    });
  }

  isEmpty() {
    return this.settings.length === 0;
  }

  getActiveSettingId(setting_id: number) {
    const setting = this.selectByRamps(setting_id);
    if (this.isActive && setting?.isActive) {
      return setting.blockSetConfigId;
    }
    return null;
  }

  isSettingActive(setting_id: number | null): boolean {
    if (setting_id == null) return false;
    const setting = this.selectByRamps(setting_id);
    return this.isActive && (setting?.isActive || false);
  }
}
