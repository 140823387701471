import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useSyncExternalStore } from "react";

const useNetworkStatus = () => {
  // Abonnements pour useSyncExternalStore
  const subscribe = (callback: () => void) => {
    window.addEventListener("online", callback);
    window.addEventListener("offline", callback);
    return () => {
      window.removeEventListener("online", callback);
      window.removeEventListener("offline", callback);
    };
  };

  // Utilisation de useSyncExternalStore
  return useSyncExternalStore(
    subscribe,
    () => navigator.onLine, // SnapShot : renvoie l'état actuel du réseau
    () => true, // Valeur fallback (toujours en ligne côté serveur)
  );
};

export default function OfflineDialog() {
  const isOnline = useNetworkStatus();
  return (
    <Dialog open={!isOnline}>
      <DialogTitle sx={{ textAlign: "center" }}>Pas de connexion Internet.</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Connexion Internet lente ou inexistante. Vérifiez votre connexion et réessayez.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            window.location.reload();
          }}
        >
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
