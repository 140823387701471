/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unescaped-entities */
import { useState, useContext, ChangeEvent, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import TextField from "@mui/material/TextField";
import Autocomplete, { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import axios from "axios";
import styles from "./FilterPrescriptions.module.css";
import DialogSavePrescriptions from "../DialogSavePrescriptions/DialogSavePrescriptions";
import AppContext from "../../../AppContext";
import Block from "../../../classes/Block";
import { API_URL_PRESCRIPTIONS_INJECTABLE, SOLVENT_NICE_IDS } from "../../../constants";
import sendInjectablePrescriptions from "../../../hooks/sendInjectablePrescriptions";
import i18n from "../../../i18n";

type Props = {
  setRecompute: (recompute: boolean) => void;
};

export default function FilterPrescriptions({ setRecompute }: Props) {
  // Context
  const {
    currentBlockSetId,
    setCurrentBlockSetId,
    blocksSet,
    setNumBlocks,
    updateBlockSet,
    token,
    sessionId,
    setSelNiceIds,
    currentSetting,
    getDrugsFromNiceIds,
    ivPrescriptionsData,
    setIvPrescriptionsData,
    updateIvPrescriptionsData,
    setUpdateIvPrescriptionsData,
    setIsUnauthorizedInjectablePrescriptions,
    setIsInternalInjectablePrescriptions,
    solventItems,
  } = useContext(AppContext);

  // Hooks
  const [saveDialogIsOpen, setSaveDialogIsOpen] = useState(false);
  const [patientFirstName, setPatientFirstName] = useState("");
  const [soignantName, setSoignantName] = useState("");

  // Getting the current prescriptions
  const url = API_URL_PRESCRIPTIONS_INJECTABLE;

  const fetchData = async () => {
    if (!token) return;

    try {
      const result = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setIvPrescriptionsData(result.data.prescriptions);
    } catch (error: any) {
      if (error.response.status === 401) {
        setIsUnauthorizedInjectablePrescriptions(true);
      } else if (error.response.status === 500) {
        setIsInternalInjectablePrescriptions(true);
      }
    }
    setUpdateIvPrescriptionsData(false);
  };

  useEffect(() => {
    fetchData();
  }, [token, updateIvPrescriptionsData]);

  let prescriptionNames: string[] = [];
  if (Array.isArray(ivPrescriptionsData)) {
    prescriptionNames = ivPrescriptionsData.map(
      (prescription: InjectablePrescriptions) => prescription.name,
    );
  }

  // Getting the current date to the correct format:
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const year = currentDate.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;

  // Buttons related to the dialog state:
  const handleSaveButtonClick = () => {
    setSaveDialogIsOpen(true);
  };

  const handleCancelButtonClick = () => {
    setSaveDialogIsOpen(false);
  };

  /**
   * A function which saves the prescription by adding it to the db
   */
  const handleConfirmSaveClick = async () => {
    // Initializing the prescription based on user input:
    const nameValue =
      newPrescriptionName !== prescriptionName ? newPrescriptionName : prescriptionName;
    const patientNameValue = patientFirstName || "";
    const prescriberNameValue = soignantName || "";
    const blocksValue: {
      block_number: number;
      drug_ids: string[];
      solvent_id: string;
    }[] = [];
    if (blocksSet) {
      let blockNumber = 1;
      blocksSet.blocks.forEach((block) => {
        const drugsNiceIds: string[] = [];
        block.drugs.forEach((drug) => {
          drugsNiceIds.push(drug.nice_id);
        });
        const newBlock = {
          block_number: blockNumber,
          drug_ids: drugsNiceIds,
          solvent_id: block.solventId,
        };
        blocksValue.push(newBlock);
        blockNumber += 1;
      });
    }
    await sendInjectablePrescriptions(
      nameValue,
      patientNameValue,
      prescriberNameValue,
      currentBlockSetId,
      blocksValue,
      token,
      sessionId,
    );

    // Reseting field values
    setPatientFirstName("");
    setSoignantName("");
    setSaveDialogIsOpen(false);

    // Fetch data after sending the prescription
    await fetchData();
  };

  /**
   * Deal with user input on the PatientName field
   */
  const handlePatientFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPatientFirstName(event.target.value);
  };

  /**
   * Deal with user input on the SoignantName field
   */
  const handleSoignantNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSoignantName(event.target.value);
  };

  const generateNewPrescriptionName = () => {
    // Get existing prescription names:
    const existingNames = new Set(prescriptionNames);
    const existingPrescriptions = ivPrescriptionsData;
    existingPrescriptions.forEach((prescription) => {
      existingNames.add(prescription.name);
    });

    // Deal with conflictiong names:
    let newPrescriptionName = prescriptionName;
    let counter = 2;
    while (existingNames.has(newPrescriptionName)) {
      newPrescriptionName = `${prescriptionName}_${counter}`;
      counter += 1;
    }
    return newPrescriptionName;
  };

  const formattedFirstName = patientFirstName.replace(/\s+/g, "_");
  const formattedPrescriberName = soignantName.replace(/\s+/g, "_");

  const prescriptionName = `${formattedDate}_${
    patientFirstName ? formattedFirstName : "ordonnance"
  }${soignantName ? `_${formattedPrescriberName}` : ""}`;

  const newPrescriptionName = generateNewPrescriptionName();

  /**
   * A function used to display the information corresponding to the selected prescription in the ramps
   *
   * @param selectedPrescription The selected Prescription object
   */
  const handleSelection = (selectedPrescription: InjectablePrescriptions | null) => {
    // Click on nothing:
    if (!selectedPrescription) {
      return;
    }

    // Cancel Optimization of the ramps:
    setRecompute(false);

    // Set the correct block number for app context:
    setCurrentBlockSetId(selectedPrescription.block_set_id);
    setNumBlocks(selectedPrescription.blocks.length);

    // Set correct selected items for app context:
    const test: string[] = [];
    SOLVENT_NICE_IDS.forEach((id) => {
      const drugId = parseInt(id.slice(2), 10);
      const solItem = solventItems.find((item) => item.drug_id === drugId);
      if (solItem) {
        test.push(solItem.nice_id);
      }
    });
    const blocksValueTest: Block[] = [];
    selectedPrescription.blocks.forEach((block) => {
      const newBlock = new Block();
      newBlock.drugs = getDrugsFromNiceIds(block.drug_ids);
      newBlock.solventId = block.solvent_id;
      blocksValueTest.push(newBlock);
      block.drug_ids.forEach((id: string) => {
        test.push(id);
      });
    });
    setSelNiceIds(test);
    // Set the blockset for the app:
    if (!blocksSet) {
      console.error("NULL BLOCKSET IN FILTER PRESCRIPTION");
      return;
    }
    blocksSet.setSelItems(getDrugsFromNiceIds(test), currentSetting);
    blocksSet.setBlocks(blocksValueTest);
    updateBlockSet();
  };

  return (
    <div className={styles.main}>
      {/* Save */}
      <IconButton
        sx={{
          width: "38px",
          height: "38px",
          marginRight: "10px",
          backgroundColor: "var(--green)",
          borderRadius: "5px !important",
          color: "var(--white)",
          border: "3px solid var(--green)",
          transition: "all 0.2s ease-out",
          "&:hover": {
            backgroundColor: "transparent",
            color: "var(--green)",
          },
        }}
        onClick={handleSaveButtonClick}
        id="save-prescription-button"
      >
        <Tooltip placement="top" title={i18n.t("general.saveprescription")}>
          <SaveIcon />
        </Tooltip>
      </IconButton>
      {/* component DialogSavePrescriptions */}
      <DialogSavePrescriptions
        open={saveDialogIsOpen}
        patientFirstName={patientFirstName}
        soignantName={soignantName}
        newPrescriptionName={newPrescriptionName}
        handleCancelButtonClick={handleCancelButtonClick}
        handleConfirmSaveClick={handleConfirmSaveClick}
        handlePatientFirstNameChange={handlePatientFirstNameChange}
        handleSoignantNameChange={handleSoignantNameChange}
      />

      {/* Filter */}
      <Autocomplete
        onChange={(event, newValue) => {
          handleSelection(newValue);
        }}
        disablePortal
        clearOnEscape
        size="small"
        sx={{
          width: "89%",
          borderRadius: "0 !important",
          border: "0px solid #ccc",
          "@media (max-width: 1024px)": {
            border: "0px solid var(--grey)",
            backgroundColor: "var(--white)",
            color: "var(--black)",
            borderRadius: "5px !important",
          },

          "& .MuiInputLabel-root.Mui-focused": {
            color: "var(--black)",
            marginTop: "3px",
            "@media (max-width: 1024px)": {
              color: "var(--white)",
            },
          },
          "& .MuiInputLabel-root.MuiInputLabel-shrink": {
            marginTop: "3px",
          },
        }}
        id="search-prescription"
        options={ivPrescriptionsData}
        getOptionLabel={(option) => {
          return option.name;
        }}
        isOptionEqualToValue={(option, value) => {
          return option.name === value.name;
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <TextField {...params} label={i18n.t("formesOrales.PanelOther.label")} />
        )}
      />
    </div>
  );
}
