import { useContext } from "react";
import AppContext from "../../../AppContext";
import styles from "./BlockDrugDisplay.module.css";
import BlockDrugIncomp from "../BlockDrugIncomp/BlockDrugIncom";
import sendTrackBlocks from "../../../hooks/sendTrackBlocks";

type Props = {
  drug: DrugOrSynonym;
  incompatibilities: BlockDrugIncompatibilities;
  onIpcClick: () => void;
  onMoveDrug: (drug: DrugOrSynonym, direction: "left" | "right") => void;
};

export default function BlockDrugDisplay({
  drug,
  incompatibilities,
  onIpcClick,
  onMoveDrug,
}: Props) {
  const { token, sessionId, selNiceIds, showNoInfoIncomp, showCompatibilities } =
    useContext(AppContext);

  // Add incompatibilities
  const incompItems = [];

  // Add incompatible solvents
  // console.log(incompatibilities.solvent);
  const incompSolvents = incompatibilities.solvent
    .filter(
      (item) =>
        item.type_incomp === "incompatible" || item.type_incomp === "contradictory",
    )
    .map((item) => (
      <BlockDrugIncomp
        targetType="solvent"
        targetName={item.name}
        onIpcClick={onIpcClick}
        key={`blockdrugincomp-${item.nice_id}-${item.name}`}
        niceId={item.nice_id}
        targetIncomp="incompatible"
      />
    ));

  incompItems.push(...incompSolvents);

  // Add incompatible drugs
  const incompDrugs = incompatibilities.drugs
    .filter(
      (item) =>
        item.type_incomp === "incompatible" || item.type_incomp === "contradictory",
    )
    .map((item) => (
      <BlockDrugIncomp
        targetType="drug"
        targetName={item.name}
        onIpcClick={onIpcClick}
        key={`blockdrugincomp-${item.nice_id}`}
        niceId={item.nice_id}
        targetIncomp="incompatible"
      />
    ));

  incompItems.push(...incompDrugs);

  // Add compatible drugs
  const compDrugs = showCompatibilities
    ? incompatibilities.drugs
        .filter((item) => item.type_incomp === "compatible")
        .map((item) => (
          <BlockDrugIncomp
            targetType="drug"
            targetName={item.name}
            onIpcClick={(): void => {}}
            key={`blockdrugincomp-${item.nice_id}-${item.name}`}
            niceId={item.nice_id}
            targetIncomp="compatible"
          />
        ))
    : [];

  incompItems.push(...compDrugs);

  // Add compatible solvents
  const compSolvents = showCompatibilities
    ? incompatibilities.solvent
        .filter((item) => item.type_incomp === "compatible")
        .map((item) => (
          <BlockDrugIncomp
            targetType="solvent"
            targetName={item.name}
            onIpcClick={(): void => {}}
            key={`blockdrugincomp-${item.nice_id}}`}
            niceId={item.nice_id}
            targetIncomp="compatible"
          />
        ))
    : [];

  incompItems.push(...compSolvents);

  // Add unknown solvents
  if (showNoInfoIncomp) {
    const unknownSolvents = incompatibilities.solvent
      .filter((item) => item.type_incomp === "no_info")
      .map((item) => (
        <BlockDrugIncomp
          targetType="solvent"
          targetName={item.name}
          onIpcClick={(): void => {}}
          key={`blockdrugincomp-${item.nice_id}`}
          niceId={item.nice_id}
          targetIncomp="no_info"
        />
      ));

    incompItems.push(...unknownSolvents);
  }

  // Add unknown drugs
  if (showNoInfoIncomp) {
    const unknownDrugs = incompatibilities.drugs
      .filter((item) => item.type_incomp === "no_info")
      .map((item) => (
        <BlockDrugIncomp
          targetType="drug"
          targetName={item.name}
          onIpcClick={(): void => {}}
          key={`blockdrugincomp-${item.nice_id}-${item.name}`}
          niceId={item.nice_id}
          targetIncomp="no_info"
        />
      ));

    incompItems.push(...unknownDrugs);
  }

  function handleMoveDrug(direction: "left" | "right") {
    onMoveDrug(drug, direction);
    sendTrackBlocks(selNiceIds, drug.nice_id, `move-${direction}`, token, sessionId);
  }

  return (
    <div className={styles.main}>
      <div
        className={styles.leftArrow}
        onClick={() => handleMoveDrug("left")}
        id={`left-arrow-${drug.nice_id}`}
      >
        <div>{"<"}</div>
      </div>
      <div className={styles.container}>
        <div className={styles.drugName}>
          <p>{drug.name}</p>
        </div>
        <div className={styles.incompatibilities}>{incompItems}</div>
      </div>
      <div
        className={styles.rightArrow}
        onClick={() => handleMoveDrug("right")}
        id={`right-arrow-${drug.nice_id}`}
      >
        <div>{">"}</div>
      </div>
      <div
        className={styles.upArrow}
        onClick={() => handleMoveDrug("left")}
        id={`left-arrow-${drug.nice_id}`}
      >
        <div className={styles.arrow}>&#7463;</div>
      </div>
      <div
        className={styles.downArrow}
        onClick={() => handleMoveDrug("right")}
        id={`right-arrow-${drug.nice_id}`}
      >
        <div className={styles.arrow}>&#7456;</div>
      </div>
    </div>
  );
}
