import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import env from "react-dotenv";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { useContext, useState } from "react";
import { useMediaQuery } from "@mui/material";
import AppContext from "../../../AppContext";
import sendTrackBlocks from "../../../hooks/sendTrackBlocks";

type Props = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};

export default function DialogOptimizeExact({ isOpen, setIsOpen }: Props) {
  const {
    blocksSet,
    selNiceIds,
    token,
    sessionId,
    settingSet,
    onOptimizeBlocks,
    currentBlockSetId,
  } = useContext(AppContext);
  const [sliderVal, setSliderVal] = useState(7);
  const numCombs = blocksSet?.totalCombs || 0;
  const maxCombs = Number(env.MAX_COMBS_OPT);

  function onClickNo() {
    setIsOpen(false);
    sendTrackBlocks(selNiceIds, null, "opt_exact_modal_no", token, sessionId);
  }

  function onClickYes() {
    setIsOpen(false);
    sendTrackBlocks(selNiceIds, null, "opt_exact_modal_yes", token, sessionId);
    onOptimizeBlocks(
      "optimize_exact_thr",
      currentBlockSetId,
      settingSet.isSettingActive(currentBlockSetId),
    );
  }

  // Responsive look of the text:
  const windowWidth = useMediaQuery("(max-width: 768px)") ? "auto" : "100%";

  let actionExact;
  if (numCombs > maxCombs) {
    actionExact = (
      <>
        <DialogContentText sx={{ marginBottom: "5px" }}>
          Nous pourrons parcourir aléatoirement seulement{" "}
          <strong>{maxCombs.toLocaleString()} combinaisons</strong>, ce qui ne guarantit
          pas de trouver une bonne solution, et ça risque de prendre du temps !
        </DialogContentText>
        <DialogContentText>
          Conseil: <strong>diminuez le nombre de lumières ouvertes</strong> ou{" "}
          <strong>utilisez l&apos;algorithme rapide</strong>.
        </DialogContentText>
      </>
    );
  } else {
    actionExact = (
      <DialogContentText>
        Nous allons <strong>toutes</strong> les parcourir, ceci risque de prendre du temps
        !
      </DialogContentText>
    );
  }

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogTitle sx={{ textAlign: "center" }}>Trouver toutes les solutions</DialogTitle>
      <DialogContent
        sx={{ width: windowWidth, display: "flex", flexDirection: "column" }}
      >
        <DialogContentText sx={{ marginBottom: "5px" }}>
          Votre configuration actuelle avec {blocksSet?.numBlocks} lumières et{" "}
          {blocksSet?.numDrugs} médicaments peut donner lieu à{" "}
          <strong>{numCombs.toLocaleString()} combinaisons</strong>.
        </DialogContentText>
        {actionExact}
        <DialogContentText sx={{ marginTop: "5px", marginBottom: "10px" }}>
          Les solutions seront présentées de la manière suivante:
        </DialogContentText>
        <Box
          sx={{
            width: "20vw",
            height: "100%",
            alignSelf: "center",
          }}
        >
          <Slider
            step={1}
            marks
            min={0}
            max={15}
            valueLabelDisplay="auto"
            value={sliderVal}
            onChange={(_, newValue) => setSliderVal(Number(newValue))}
          />
        </Box>
        <DialogContentText sx={{ textAlign: "center", marginTop: "20px" }}>
          <strong>Êtes-vous sûr.e de vouloir continuer ?</strong>
        </DialogContentText>
        <DialogActions>
          <Button onClick={() => onClickYes()} id="yes-optimal">
            Oui
          </Button>
          <Button onClick={() => onClickNo()}>Non</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
