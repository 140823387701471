import { SOLVENT_NICE_IDS, MAX_NUM_BLOCKS, INIT_NUM_BLOCKS } from "../constants";
import Block, { getManyDrugs } from "./Block";

import Settings from "./Settings";

export default class BlocksSet {
  private _blockSetId: number;
  private _blocks: Block[] = [];
  private _selSolvents: DrugOrSynonym[] = [];
  private _selDrugs: DrugOrSynonym[] = [];
  private _allItems: DrugSynonymArray = [];
  private _allSolvents: DrugOrSynonym[] = [];

  constructor(
    blockSetId: number,
    numBlocks: number,
    selDrugs: DrugOrSynonym[],
    settings: Settings,
    allItems: DrugOrSynonym[],
  ) {
    this._allSolvents = allItems.filter((item) => item.is_solvent && item.is_default);
    this._blockSetId = blockSetId;
    BlocksSet.verifyNumBlocks(numBlocks);
    this.initializeBlocks(numBlocks);
    this.assignSolvents();
    this.setSelItems(selDrugs, settings);
  }

  set incompApiData(data: IncompApiResp) {
    this._blocks.forEach((block) => {
      block.incompApiData = data;
    });
  }

  /**
   * Initializes a list of empty blocks.
   * Sets the _blocks property.
   *
   * @param numBlocks the number of blocks to be initialized
   */
  initializeBlocks(numBlocks: number | null = null) {
    this._blocks = [];
    let totalBlocks = numBlocks;
    if (totalBlocks === null) {
      totalBlocks = this._blocks.length;
    }

    for (let i = 0; i < totalBlocks; i += 1) {
      const block = new Block();
      block.allSolvents = this._allSolvents;
      this._blocks.push(block);
    }
  }

  get blocks() {
    return this._blocks;
  }

  setSelItems(selItems: DrugOrSynonym[], settings: Settings) {
    // Séparer les médicaments et les solvants
    const drugs = selItems.filter((item) => !SOLVENT_NICE_IDS.includes(item.nice_id));
    this._selSolvents = selItems.filter((item) =>
      SOLVENT_NICE_IDS.includes(item.nice_id),
    );
    // Vérifier les nouveaux médicaments
    const newDrugs = drugs.filter((item) => !this._selDrugs.includes(item));
    // Vérifier les médicaments supprimés
    const removeDrugs = this._selDrugs.filter((item) => !drugs.includes(item));
    // Mettre à jour les IDs de médicaments
    this._selDrugs = [...this._selDrugs, ...newDrugs].filter(
      (item) => !removeDrugs.includes(item),
    );
    // Ajouter et supprimer des médicaments dans les blocs
    this.removeDrugs(removeDrugs);
    this.assignDrugs(newDrugs, settings);
  }

  applyApiOptimization(
    optData: BlockOptimizationData[],
    incompData: IncompApiResp,
    setting_id: number,
    numBlocks: number,
    configs: BlockSetConfig[],
    allItems: DrugSynonymArray,
  ) {
    const found = configs.find((e) => e.block_set_id === setting_id);
    numBlocks = found?.block_count || optData.length;
    // Créer de nouveaux éléments de bloc
    this.initializeBlocks(numBlocks);
    this.incompApiData = incompData;

    this._blocks.forEach((block, counter) => {
      if (counter < optData.length) {
        block.drugs = getManyDrugs(optData[counter].drugs, allItems);
        block.solventId = optData[counter].solvent;
      } else {
        block.drugs = getManyDrugs([], allItems);
        block.solventId = null;
      }
    });
  }

  assignDrugs(drugs: DrugOrSynonym[], settings: Settings) {
    // Fetch unconstrained blocks
    drugs.forEach((drug) => {
      const avaliableBlocks = settings.getAvailableBlocks(drug, this.drugIds);
      // Place the drug in the first avaliable block
      for (let i = 0; i < avaliableBlocks.length; i += 1) {
        if (avaliableBlocks[i]) {
          this._blocks[i].addDrugs([drug]);
          return;
        }
      }
    });
  }

  removeDrugs(drugItems: DrugOrSynonym[]) {
    for (const item of drugItems) {
      const numBlock = this.getDrugBlockNumber(item);

      if (numBlock !== null) {
        this._blocks[numBlock].removeDrug(item);
      }
      this._selDrugs = this._selDrugs.filter((d) => d.nice_id !== item.nice_id);
    }
  }

  removeDrugFromNiceId(niceId: string) {
    this._selDrugs = this._selDrugs.filter((d) => d.nice_id !== niceId);
  }

  /**
   * Assigns solvants to blocks.
   *
   * Sets the solventId property for each block.
   */
  assignSolvents() {
    // Assigner les solvants aux blocs
    this._blocks.forEach((block, idx) => {
      if (idx < this._selSolvents.length) {
        block.solventId = this._selSolvents[idx].nice_id;
      }
    });
  }

  get numBlocks(): number {
    return this._blocks.length;
  }

  set numBlocks(numBlocks: number) {
    BlocksSet.verifyNumBlocks(numBlocks);
    if (numBlocks >= 1 && numBlocks <= MAX_NUM_BLOCKS) {
      const diffBlocks = numBlocks - this.numBlocks;

      if (diffBlocks > 0) {
        // Ajouter de nouveaux blocs vides
        for (let i = 0; i < diffBlocks; i += 1) {
          const block = new Block();
          block.allSolvents = this._allSolvents;
          this._blocks.push(block);
        }
      } else if (diffBlocks < 0) {
        // Supprimer les derniers blocs
        for (let i = 0; i < -diffBlocks; i += 1) {
          const block = this._blocks.pop() as Block;
          const prevBlock = this._blocks.slice(-1)[0];
          // Déplacer les médicaments vers le bloc précédent
          prevBlock.addDrugs(block.drugs);
        }
      }
    } else {
      console.error(
        `Le nombre de blocs ne peut pas dépasser 6, vous avez demandé ${numBlocks + 1}`,
      );
    }
  }

  moveDrugFromBlockToBlock(
    drug: DrugOrSynonym,
    blockFrom: number,
    blockTo: number,
    order = -1,
  ) {
    this._blocks[blockFrom].removeDrug(drug);
    this._blocks[blockTo].addDrugs([drug]);
    this._blocks[blockTo].changeOrderDrug(drug, order);
  }

  getDrugBlockNumber(drug: DrugOrSynonym): number | null {
    for (let i = 0; i < this._blocks.length; i += 1) {
      if (this._blocks[i].hasDrugId(drug.nice_id)) {
        return i;
      }
    }
    return null;
  }

  /**
   * A helper function to get working items from nice_ids.
   * @param niceId the id stored in the database.
   * @returns A DrugOrSynonym item referring to the actual element.
   */
  getItem(niceId: string): DrugOrSynonym | null {
    return this._allItems.find((elem) => elem.nice_id === niceId) || null;
  }

  moveDrugLeft(drug: DrugOrSynonym, settings: Settings) {
    // Get previous position and rules:
    const numBefore = this.getDrugBlockNumber(drug);
    const avaliableBlocks = settings.getAvailableBlocks(drug, this.drugIds);

    if (numBefore !== null && numBefore > 0) {
      // Get the first avaliable block to the left:
      let i = numBefore - 1;
      while (i >= 0) {
        if (!settings.isActive) {
          this.moveDrugFromBlockToBlock(drug, numBefore, i);
          return;
        }
        if (avaliableBlocks[i]) {
          this.moveDrugFromBlockToBlock(drug, numBefore, i);
          return;
        }
        i -= 1;
      }
    }
    throw new Error(
      `Des règles empêchent le médicament ${drug.name} d'être déplacé vers la gauche ou il est dans la première rampe.`,
    );
  }

  moveDrugRight(drug: DrugOrSynonym, settings: Settings) {
    // Get previous position and rules:
    const numBefore = this.getDrugBlockNumber(drug);
    const avaliableBlocks = settings.getAvailableBlocks(drug, this.drugIds);

    if (numBefore !== null && numBefore < this.numBlocks - 1) {
      // Get the first avaliable block to the left:
      let i = numBefore + 1;
      while (i <= this.numBlocks - 1) {
        if (!settings.isActive) {
          this.moveDrugFromBlockToBlock(drug, numBefore, i);
          return;
        }
        if (avaliableBlocks[i]) {
          this.moveDrugFromBlockToBlock(drug, numBefore, i);
          return;
        }
        i += 1;
      }
    }
    throw new Error(
      `Des règles empêchent le médicament ${drug.name} d'être déplacé vers la droite ou il est dans la dernière rampe.`,
    );
  }

  /**
   * Verifies whether the number of blocks defined is allowed
   *
   * @param numBlocks the number of blocks
   * @throws a new Error if the number of blocks is greater than MAX_NUM_BLOCKS
   */
  static verifyNumBlocks(numBlocks: number) {
    if (numBlocks > MAX_NUM_BLOCKS) {
      throw new Error(
        `Le nombre maximum de blocs est de ${MAX_NUM_BLOCKS}, mais vous en avez demandé ${numBlocks}`,
      );
    }
  }

  get drugIds() {
    return this._selDrugs.map((drug) => drug.drug_id);
  }

  get numDrugs() {
    return this._selDrugs.length;
  }

  get numCombsDrugs() {
    return this.numBlocks ** this.drugIds.length;
  }

  get numCombsSolvents() {
    return SOLVENT_NICE_IDS.length ** this.numBlocks;
  }

  get totalCombs() {
    return this.numCombsDrugs * this.numCombsSolvents;
  }

  setBlockSetId(id: number, configs: BlockSetConfig[]) {
    this._blockSetId = id;
    const found = configs.find((e) => e.block_set_id === id);
    const numBlocks = found?.block_count || INIT_NUM_BLOCKS;
    const diffBlocks = numBlocks - this.numBlocks;
    if (diffBlocks > 0) {
      // Ajouter de nouveaux blocs vides
      for (let i = 0; i < diffBlocks; i += 1) {
        const block = new Block();
        block.allSolvents = this._allSolvents;
        this._blocks.push(block);
      }
    } else if (diffBlocks < 0) {
      // Supprimer les derniers blocs
      for (let i = 0; i < -diffBlocks; i += 1) {
        const block = this._blocks.pop() as Block;
        const prevBlock = this._blocks.slice(-1)[0];
        // Déplacer les médicaments vers le bloc précédent
        prevBlock.addDrugs(block.drugs);
      }
    }
  }

  get blockSetId() {
    return this._blockSetId;
  }

  /**
   * A function used to specifically get a new blockset from a list of blocks.
   *
   * Sets the properties _blocks, numBlocks, _drugIds and  _solventIds .
   *
   * @param blockList the list of blocks to be assigned.
   * @throws An error if the blockList contains too many blocks.
   *
   */
  setBlocks(blockList: Block[]) {
    // Setting the blocks:
    BlocksSet.verifyNumBlocks(blockList.length);
    this._blocks = blockList;
    this.numBlocks = this._blocks.length;
  }

  toJSON() {
    const blocksData = this.blocks.map((block) => block.toJSON());
    return {
      blockSetId: this._blockSetId,
      blocks: blocksData,
      drugIds: this.drugIds,
      // solventIds: this._solventIds,
      numDrugs: this.numDrugs,
      numBlocks: this.numBlocks,
      numCombsDrugs: this.numCombsDrugs,
      numCombsSolvents: this.numCombsSolvents,
      totalCombs: this.totalCombs,
    };
  }
}
