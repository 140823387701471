import { useState, useEffect } from "react";
import axios from "axios";
import { API_URL_BLOCK_SET_CONFIG } from "../constants";

export default function useFetchBlockSetConfig(token: string | null): {
  data: BlockSetConfig[];
  isLoading: boolean;
  isError: boolean;
  isUnauthorizedBlockSetConfig: boolean;
  isInternalErrBlockSetConfig: boolean;
} {
  const [data, setData] = useState<BlockSetConfig[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isUnauthorizedBlockSetConfig, setIsUnauthorizedBlockSetConfig] = useState(false);
  const [isInternalErrBlockSetConfig, setIsInternalErrBlockSetConfig] = useState(false);

  const url = API_URL_BLOCK_SET_CONFIG;

  useEffect(() => {
    const fetchData = async () => {
      if (!token) return;
      setIsError(false);
      setIsLoading(true);

      try {
        const result = await axios.get(url, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setData(result.data);
      } catch (error: any) {
        setIsError(true);
        if (error.response.status === 401) {
          setIsUnauthorizedBlockSetConfig(true);
        } else if (error.response.status === 500) {
          setIsInternalErrBlockSetConfig(true);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  return {
    data,
    isLoading,
    isError,
    isUnauthorizedBlockSetConfig,
    isInternalErrBlockSetConfig,
  };
}
