import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import styles from "./DialogSfpcRec.module.css";
import i18n from "../../../i18n";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  setIsOpen: (open: boolean) => void;
};
export default function SfpcRecDialog({ isOpen, handleClose, setIsOpen }: Props) {
  return (
    <Dialog open={isOpen} onClose={handleClose} className={styles.main}>
      <DialogTitle className={styles.title}>
        {i18n.t("Menu.SfpcRecDialog.title")}
      </DialogTitle>
      <DialogContent className={styles.content}>
        <div className={styles.horizontal}>
          <h3>
            <a
              href="https://sfpc.eu/wp-content/uploads/2022/11/Socle-perfusion-GT-O3P-SFPC_21nov22.pdf"
              target="_blank"
              rel="noreferrer"
            >
              {i18n.t("Menu.SfpcRecDialog.sfpcRec")}
            </a>
          </h3>
          <div className={styles.organigram}>
            <div className={styles.orga_box}>
              <b>1.</b> Adaptation des modalités d&apos;administration (privilégier une
              administration <b>discontinue</b> à des temps différents)
            </div>
            <div className={styles.orga_sep}>
              <span className={styles.orga_arrow}>↓</span>
              <b>ou</b>
            </div>
            <div className={styles.orga_box}>
              <b>2.</b> Utilisation d&apos;une <b>autre lumière</b> sur le même cathéter
              ou d&apos;un <b>autre abord veineux</b>
            </div>
            <div className={styles.orga_sep}>
              <span className={styles.orga_arrow}>↓</span>
              <b>ou</b>
            </div>
            <div className={styles.orga_box}>
              <b>3.</b> Possibilité de relais par <b>voie orale ou SNG</b>
            </div>
            <div className={styles.orga_sep}>
              <span className={styles.orga_arrow}>↓</span>
              <b>ou</b>
            </div>
            <div className={styles.orga_box}>
              <b>4.</b> <b>Intérêt du médicament</b> comparé aux risques liés à l&apos;IPC
              (inefficacité, obstruction mécanique du cathéter à l&apos;origine de
              thrombose ou d&apos;une infection, etc.)
            </div>
          </div>
        </div>
        <div>
          <h2 className={styles.note}>Merci de contacter votre pharmacien.</h2>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setIsOpen(false)} id="close-button-dialog-info-rampes">
          {i18n.t("Menu.SfpcRecDialog.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
