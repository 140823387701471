import axios from "axios";
import { SOLVENT_NICE_IDS, API_URL_BIGS_FIRST } from "../constants";

export default async function fetchBlocksOptimization(
  selNiceIds: string[],
  maxNumBlocks: number,
  token: string | null,
  sessionId: string | null,
  algorithmName: string,
  settingId: number | null,
  isActive: boolean,
): Promise<BlockOptimizationData[][] | null> {
  // Verify token
  if (!token || !sessionId) {
    console.error("token or sessionId not defined", { token, sessionId });
    return null;
  }
  const url = API_URL_BIGS_FIRST;
  const bodyReq = {
    session_id: sessionId,
    sel_nice_ids: selNiceIds,
    solvent_ids: SOLVENT_NICE_IDS,
    block_count: maxNumBlocks,
    max_num_blocks: maxNumBlocks,
    settings_id: settingId,
    block_set_id: settingId,
    is_active: isActive,
  };

  try {
    const response = await axios.post(url, bodyReq, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (err) {
    console.error(err);
    return null;
  }
}
