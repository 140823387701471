import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { SOLVENT_NICE_IDS, API_URL_IV_INCOMP_DRUGS } from "../constants";
import { defaultIncompApiData } from "../AppContext";
import SettingSet from "../classes/SettingSet";

export default function useFetchIncomp(
  selNiceIds: string[],
  allItems: DrugSynonymArray,
  blockSetId: number,
  token: string | null,
  settingSet: SettingSet,
  init: boolean,
): {
  data: IncompApiResp;
  isLoading: boolean;
  isError: boolean;
  isUnauthorizedIncomp: boolean;
  isInternalErrIncomp: boolean;
} {
  const [data, setData] = useState<IncompApiResp>(defaultIncompApiData);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isUnauthorizedIncomp, setIsUnauthorizedIncomp] = useState(false);
  const [isInternalErrIncomp, setIsInternalErrIncomp] = useState(false);

  // Reference to only send the request when needed:
  const prevIdsStr = useRef("");
  const url = API_URL_IV_INCOMP_DRUGS;

  useEffect(() => {
    // Checks whether the reference corresponds and if init is correctly done
    if (prevIdsStr.current !== selNiceIds.join("") && init) {
      // Create array of items
      const selItems = allItems.filter(
        (item) =>
          selNiceIds.includes(item.nice_id) || SOLVENT_NICE_IDS.includes(item.nice_id),
      );
      // console.log(allItems);
      const bodyReq = {
        list_items: selItems,
        solvent_ids: SOLVENT_NICE_IDS,
        block_set_id: blockSetId,
        is_active: settingSet.isSettingActive(blockSetId),
      };
      const fetchData = async () => {
        if (!token) return;
        setIsError(false);
        setIsLoading(true);

        try {
          const result = await axios.post(url, bodyReq, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setData(result.data);
        } catch (error: any) {
          setIsError(true);
          if (error.response.status === 401) {
            setIsUnauthorizedIncomp(true);
          } else if (error.response.status === 500) {
            setIsInternalErrIncomp(true);
          }
        }

        setIsLoading(false);
      };

      fetchData();
      prevIdsStr.current = selNiceIds.join("");
    }
  }, [selNiceIds.length, token, init, settingSet]);

  return { data, isLoading, isError, isUnauthorizedIncomp, isInternalErrIncomp };
}
